import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  PaginationItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ShortLine from '../../../components/ShortLine/ShortLine';
import useLanguage from '../../../common/hooks/useLanguage';
import { ArrowBack, ArrowForward, ImportExport, Description, ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import './MisReconocimientos.css';
import { modalError } from '../../../utils/validaciones';
import ES from '../../../common/language/misreconocimientos/es.json';
import EN from '../../../common/language/misreconocimientos/en.json';
import DetailModal from './DetailModal';
import { GetReconocimientosRecibidos } from '../../../services/ReconocimientosAsignados';
import { ReconocimientoAsignadoInterface } from '../../../common/interfaces/ReconocimientoAsignadoInterface';
import { formatDate } from '../../../utils/datesUtils';
import { LanguageContext, ThemeContext } from '../../../context';

const ReconocimientoAsignado = () => {
  const [data, setData] = useState<ReconocimientoAsignadoInterface[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [orden, setOrden] = useState<string>('fecha desc');
  const language = useContext(LanguageContext);
  const { dictionary } = useLanguage({ EN, ES });

  const [selectedRecognition, setSelectedRecognition] = useState<ReconocimientoAsignadoInterface | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const theme = React.useContext(ThemeContext);

  const handleOpenModal = (recognition: ReconocimientoAsignadoInterface) => {
    setSelectedRecognition(recognition);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setLoading(true);
    GetReconocimientosRecibidos(language.language, page, 10, orden)
      .then((response) => {
        setData(response.data);
        setTotalPages(response.cantidadPaginas);
      })
      .catch((err) => {
        modalError('Se ha producido un error');
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [language.language, page, orden]);

  const invertirOrden = (campo) => {
    setOrden(orden.includes('desc') ? `${campo} asc` : `${campo} desc`);
  };

  const handleChangePag = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <div className="mis-movimientos-page">
      <Typography variant="h4" color="primary">
        {dictionary?.misReconocimientos}
        <ShortLine position="left" />
      </Typography>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={3}
        border={2}
        borderColor={'primary.main'}
        borderRadius={3}
        className="mis-mov-table-container"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#E9E9E9' }}>
                {[
                  {
                    title: 'ID',
                    key: 'idReconocimiento',
                    hasOrdering: true,
                  },
                  {
                    title: dictionary?.tabla.fecha,
                    key: 'fecha',
                    hasOrdering: true,
                  },
                  {
                    title: dictionary?.tabla.nombreUsuarioAsignador,
                    key: 'nombreUsuarioAsignador',
                    hasOrdering: true,
                  },
                  {
                    title: dictionary?.tabla.tituloTipoReconocimiento,
                    key: 'tituloTipoReconocimiento',
                    hasOrdering: true,
                  },
                  {
                    title: dictionary?.tabla.detalle,
                    key: 'detalle',
                    hasOrdering: false,
                  },
                ].map((column) => {
                  return (
                    <TableCell
                      key={column.key}
                      align="center"
                      sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                      onClick={() => column?.hasOrdering && invertirOrden(column.key)}
                    >
                      {column.title}
                      {column?.hasOrdering ? (
                        orden.includes(`${column.key}`) === column.title ? (
                          orden.includes('asc') ? (
                            <ArrowDropUp fontSize="small" />
                          ) : (
                            <ArrowDropDown fontSize="small" />
                          )
                        ) : (
                          <ImportExport fontSize="small" />
                        )
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                    <CircularProgress />
                    <div>{dictionary?.cargando}</div>
                  </TableCell>
                </TableRow>
              ) : data && data.length > 0 ? (
                data.map((row) => (
                  <TableRow key={row.idReconocimiento}>
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell align="center">{formatDate(row.fecha)}</TableCell>
                    <TableCell align="center">{row.nombreUsuarioAsignador}</TableCell>
                    <TableCell align="center">{row.tituloTipoReconocimiento}</TableCell>
                    <TableCell align="center">
                      <Button onClick={() => handleOpenModal(row)} startIcon={<Description />} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    {dictionary?.sinResultados}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 2,
          }}
        >
          <Pagination
            color="primary"
            shape="rounded"
            variant="outlined"
            page={page}
            count={totalPages}
            onChange={handleChangePag}
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBack, next: ArrowForward }}
                {...item}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: theme?.theme && theme?.theme[10] ? theme?.theme[10].color : 'primary', // Color de fondo para el número de página seleccionado
                    color: 'white', // Color del texto para el número de página seleccionado
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
      {selectedRecognition && (
        <DetailModal open={openModal} onClose={handleCloseModal} recognition={selectedRecognition} />
      )}
    </div>
  );
};

export default ReconocimientoAsignado;
