import { Box, Button, Typography } from '@mui/material';
import ShortLine from '../../components/ShortLine/ShortLine';
import MiEquipoTable from './components/MiEquipoTable/MiEquipoTable';
import useMiEquipo from '../../common/hooks/useMiEquipo';
import { useQuery } from 'react-query';
import { getBudgetSupervisor } from '../../services/MiEquipoService';
import CustomSearch from '../../components/CustomSearch/CustomSearch';

const MiEquipo = () => {
  const {
    idUsuario,
    loading,
    usuSearch,
    dictionary,
    page,
    totalPages,
    orden,
    setPage,
    setSearch,
    cambiarOrdenamiento,
  } = useMiEquipo();

  const { data: budgetSupervisor } = useQuery({
    queryKey: ['budget-supervisor-key', idUsuario],
    queryFn: async () => await getBudgetSupervisor(idUsuario),
  });

  const formatNumber = (num: number) => {
    return new Intl.NumberFormat('es-ES').format(num);
  };

  return (
    <Box padding={'30px 20px'}>
      <Typography variant="h4" color="primary">
        {dictionary?.title}
      </Typography>

      <ShortLine position="left" />
      {idUsuario === budgetSupervisor?.idSupervisor ? (
        <Button variant="contained">
          <Typography variant="h6">
            {dictionary?.budget}: ${formatNumber(budgetSupervisor?.presupuesto) || 0}
          </Typography>
        </Button>
      ) : null}
      <section
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box border={2} borderColor={'primary.main'} borderRadius={3} padding={'30px'} width={'100%'} marginTop={2}>
          <CustomSearch setSearch={setSearch} setPage={setPage} placeHolder={`${dictionary?.searchForm.placeHolder}`} />
          <MiEquipoTable
            usuarios={usuSearch}
            loading={loading}
            totalPages={totalPages}
            page={page}
            orden={orden}
            dictionary={dictionary}
            setPage={setPage}
            cambiarOrdenamiento={cambiarOrdenamiento}
          />
        </Box>
      </section>
    </Box>
  );
};

export default MiEquipo;
