import {
  Box,
  Pagination,
  PaginationItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import ShortLine from '../../components/ShortLine/ShortLine';
import useLanguage from '../../common/hooks/useLanguage';
import ES from '../../common/language/mismovimientos/es.json';
import EN from '../../common/language/mismovimientos/en.json';
import { ArrowBack, ArrowForward, ImportExport, ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { GetMovimientosByPersonaDataTable } from '../../services/MisMovimientosService';
import { misMovimientosInterface } from '../../common/interfaces/MisMovimientosInterface';
import './MisMovimientos.css';
import { modalError } from '../../utils/validaciones';
import { LanguageContext, ThemeContext } from '../../context';
import CustomSearch from '../../components/CustomSearch/CustomSearch';

const MisMovimientos = () => {
  const [misMovimientos, setMisMovimientos] = useState<misMovimientosInterface[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [orden, setOrden] = useState<{ campo: string; ascendente: boolean }>({
    campo: 'fecha',
    ascendente: false,
  });
  const [search, setSearch] = useState<string>('');
  const language = useContext(LanguageContext);
  const { dictionary } = useLanguage({ EN, ES });
  const theme = React.useContext(ThemeContext);

  const fetchData = useCallback(() => {
    setLoading(true);
    const direccion = orden.ascendente ? 'asc' : 'desc';
    const order = `${orden.campo} ${direccion}`;
    const movimientoDescripcion = search ?? '';
    let params = {
      idioma: language.language,
      pagina: page,
      ordenamiento: order,
      CantidadRegistrosPorPagina: 10,
      ...(movimientoDescripcion && { movimientoDescripcion }),
    };

    GetMovimientosByPersonaDataTable(params)
      .then((response) => {
        setMisMovimientos(response.data);
        setTotalPages(response.cantidadPaginas);
      })
      .catch((err) => {
        modalError('Se ha producido un error');
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [language.language, page, orden, search]);

  const cambiarOrdenamiento = (campo: string) => {
    setOrden((ordenActual) => {
      const esAscendente = ordenActual.campo === campo ? !ordenActual.ascendente : true;
      return { campo, ascendente: esAscendente };
    });
  };

  const handleChangePag = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    fetchData();
  }, [language.language, page, orden, search, fetchData]);

  return (
    <div className="mis-movimientos-page">
      <Typography variant="h4" color="primary">
        {dictionary?.misMovimientos}
        <ShortLine position="left" />
      </Typography>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={3}
        border={2}
        borderColor={'primary.main'}
        borderRadius={3}
        className="mis-mov-table-container"
      >
        <CustomSearch setSearch={setSearch} setPage={setPage} placeHolder={dictionary?.placeHolder} />
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow sx={{ backgroundColor: '#E9E9E9' }}>
                {[
                  {
                    label: 'ID',
                    campo: 'idMovimiento',
                    hasOrdering: true,
                  },
                  {
                    label: dictionary?.tabla.fecha,
                    campo: 'fecha',
                    hasOrdering: true,
                  },
                  {
                    label: dictionary?.tabla.tipoTransaccion,
                    campo: 'idTipoMovimiento',
                    hasOrdering: true,
                  },
                  {
                    label: dictionary?.tabla.estado,
                    campo: 'idEstado',
                    hasOrdering: true,
                  },
                  {
                    label: dictionary?.tabla.puntos,
                    campo: 'puntos',
                    hasOrdering: true,
                  },
                  {
                    label: dictionary?.tabla.detalle,
                    campo: 'detalle',
                    hasOrdering: false,
                  },
                ].map((columna) => (
                  <TableCell
                    align="center"
                    key={`header-${columna.campo}`}
                    sx={{
                      fontWeight: 'bold',
                      position: 'relative',
                      cursor: columna.hasOrdering ? 'pointer' : 'default',
                    }}
                    onClick={() => columna.hasOrdering && cambiarOrdenamiento(columna.campo)}
                  >
                    <div className="row-header-reco">
                      {columna.label}
                      {columna?.hasOrdering ? (
                        orden.campo === columna.campo ? (
                          orden.ascendente ? (
                            <ArrowDropUp fontSize="small" />
                          ) : (
                            <ArrowDropDown fontSize="small" />
                          )
                        ) : (
                          <ImportExport fontSize="small" />
                        )
                      ) : null}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                    <CircularProgress />
                    <div>{dictionary?.cargando}</div>
                  </TableCell>
                </TableRow>
              ) : misMovimientos && misMovimientos.length > 0 ? (
                misMovimientos.map((row, index) => (
                  <TableRow
                    key={'body-' + row.idMovimiento + index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {row.idMovimiento}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.fechaCorta}
                    </TableCell>
                    <TableCell align="center">{row.tipoMovimientoDescripcion}</TableCell>
                    <TableCell align="center">
                      <div className="mis-mov-estado-container">
                        <div className={`mis-mov-estado ${row.estado === 'Pendiente' && 'mis-mov-estado-pendiente'}`}>
                          {row.estado}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">{row.productosDescripcion}</TableCell>
                    <TableCell align="center">{row.puntos}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    {dictionary?.sinResultados}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stack spacing={2}>
            <Pagination
              color="primary"
              shape="rounded"
              variant="outlined"
              page={page}
              count={totalPages}
              onChange={handleChangePag}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: theme?.theme && theme?.theme[10] ? theme?.theme[10].color : 'primary', // Color de fondo para el número de página seleccionado
                      color: 'white', // Color del texto para el número de página seleccionado
                    },
                  }}
                />
              )}
            />
          </Stack>
        </Box>
      </Box>
      <Box marginBottom={25}></Box>
    </div>
  );
};

export default MisMovimientos;
