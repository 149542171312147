import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Box, Checkbox, Pagination, PaginationItem, Stack, CircularProgress, Paper } from '@mui/material';
import { ReconocimientoInterface, myReconocimientoInterface } from '../../../common/interfaces/ReconocimientoInterface';
import Swal from 'sweetalert2';
import { formatDate } from '../../../utils/datesUtils';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { IReconocimientos } from '../interfaces/Reconocimientos';
import { LanguageContext, ThemeContext } from '../../../context';

type Props = {
  recoRealizados: myReconocimientoInterface[] | null;
  form: ReconocimientoInterface;
  setForm: React.Dispatch<React.SetStateAction<ReconocimientoInterface>>;
  loading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  cambiarOrdenamiento: (campo: string) => void;
  orden: { campo: string; ascendente: boolean | null };
  dictionary?: IReconocimientos;
};

export const ReconocimientosAprobadosTable: React.FC<Props> = ({
  recoRealizados,
  form,
  loading,
  page,
  totalPages,
  orden,
  dictionary,
  setForm,
  setPage,
  cambiarOrdenamiento,
}) => {
  const language = useContext(LanguageContext);
  const theme = React.useContext(ThemeContext);

  const verDetalle = (persona: string, descripcion: string, idioma: string) => {
    Swal.fire({
      position: 'top',
      title: persona + (idioma === 'ES' ? ' comentario:' : "'s comment"),
      text: descripcion,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: dictionary?.cancelar,
      customClass: {
        popup: 'myreco-swal-container',
        title: 'myreco-swal-title',
        cancelButton: 'myreco-btn',
      },
    });
  };

  const handleCheckBox = (e: any, idReconocimiento: number) => {
    let arrAprobados = form.aprobados;
    e.target.checked
      ? arrAprobados && arrAprobados.push(idReconocimiento)
      : (arrAprobados = arrAprobados?.filter((el) => el !== idReconocimiento));
    setForm({ aprobados: arrAprobados });
  };

  const handleChangePag = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Box marginTop={3}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#E9E9E9' }}>
              {[
                {
                  label: '#',
                  campo: 'idReconocimiento',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.form.aprobar,
                  campo: 'aprobar',
                  hasOrdering: false,
                },
                {
                  label: dictionary?.form.fecha,
                  campo: 'fecha',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.form.tipoReconocimiento,
                  campo: 'tituloTipoReconocimiento',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.form.asignador,
                  campo: 'nombreUsuarioAsignador',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.form.empleadoReconocido,
                  campo: 'nombreUsuarioReconocido',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.form.puntos,
                  campo: 'puntos',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.form.estado,
                  campo: 'estado',
                  hasOrdering: true,
                },
                {
                  label: dictionary?.form.comentario,
                  campo: 'descripcion',
                  hasOrdering: false,
                },
              ].map((columna, index) => {
                return (
                  <TableCell
                    key={columna.campo}
                    align="center"
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => columna?.hasOrdering && cambiarOrdenamiento(columna.campo)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <Typography fontSize={14}>{columna.label}</Typography>
                      {columna?.hasOrdering ? (
                        orden.campo === columna.campo ? (
                          orden.ascendente ? (
                            <ArrowDropUpIcon fontSize="small" />
                          ) : (
                            <ArrowDropDownIcon fontSize="small" />
                          )
                        ) : (
                          <ImportExportIcon fontSize="small" />
                        )
                      ) : null}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: 'center' }}>
                  <CircularProgress />
                  <div>{dictionary?.cargando}</div>
                </TableCell>
              </TableRow>
            ) : recoRealizados && recoRealizados.length > 0 ? (
              recoRealizados.map((row, index) => (
                <TableRow key={`reco-apro-${row.idMovimiento}-${index}`}>
                  <TableCell component="th" scope="row" align="center">
                    {row.idReconocimiento}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.estado === 'APROBADO' ? (
                      <HowToRegIcon />
                    ) : (
                      <Checkbox size="small" onChange={(e) => handleCheckBox(e, row.idReconocimiento)} />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {formatDate(row.fechaString)}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {row.descripcionTipoReconocimiento}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {row.nombreUsuarioAsignador}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {row.nombreUsuarioReconocido}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {row.puntos}
                  </TableCell>
                  <TableCell component="th" align="center">
                    <Chip
                      color={row.estado === 'APROBADO' ? 'success' : 'warning'}
                      label={
                        row.estado === 'APROBADO'
                          ? dictionary?.datatable.aprobado
                          : dictionary?.datatable.pendienteAprobacion
                      }
                    />
                  </TableCell>
                  <TableCell component="th" align="center">
                    <Chip
                      onClick={() => verDetalle(row.nombreUsuarioAsignador, row.descripcion, language.language)}
                      color="primary"
                      label={dictionary?.datatable.verDetalles}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell component="th" align="center" colSpan={8}>
                {dictionary?.datatable.sinResultados}
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Stack spacing={2}>
          <Pagination
            color="primary"
            shape="rounded"
            variant="outlined"
            page={page}
            count={totalPages}
            onChange={handleChangePag}
            renderItem={(item) => (
              <PaginationItem
                components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
                sx={{
                  marginTop: 5,
                  '&.Mui-selected': {
                    backgroundColor: theme?.theme && theme?.theme[10] ? theme?.theme[10].color : 'primary', // Color de fondo para el número de página seleccionado
                    color: 'white', // Color del texto para el número de página seleccionado
                  },
                }}
              />
            )}
          />
        </Stack>
      </Box>
    </Box>
  );
};
